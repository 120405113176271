import React from "react";
import styles from "./PostCard.module.scss";
import { PostSchedule } from "../PostSchedulesCalendarListView/usePostSchedulesCalendarListView";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import UpdatePostContentModal from "../UpdatePostContentModal/UpdatePostContentModal";

interface Props {
  className?: string;
  post: PostSchedule;
}

const PostCard: React.FC<Props> = (props) => {
  const { post, className } = props;

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState<
    PostSchedule | undefined
  >(undefined);

  return (
    <div className={classNames(styles["post-card"], className)}>
      <UpdatePostContentModal
        isOpen={isEditModalOpen}
        closeModal={() => setIsEditModalOpen(false)}
        post={selectedPost as PostSchedule}
        onOk={(text) => {}}
      />
      <div className={styles["header"]}>
        <span className={styles["value-proposition"]}>LinkedIn</span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => {
            console.log("wokring....");
            setSelectedPost(post);
            setIsEditModalOpen(true);
            e.stopPropagation();
          }}
        >
          <path
            d="M3.1665 3.66667H2.49984C2.14622 3.66667 1.80708 3.80715 1.55703 4.0572C1.30698 4.30724 1.1665 4.64638 1.1665 5V11C1.1665 11.3536 1.30698 11.6928 1.55703 11.9428C1.80708 12.1929 2.14622 12.3333 2.49984 12.3333H8.49984C8.85346 12.3333 9.1926 12.1929 9.44265 11.9428C9.69269 11.6928 9.83317 11.3536 9.83317 11V10.3333M9.1665 2.33334L11.1665 4.33334M12.0898 3.38999C12.3524 3.12743 12.4999 2.77132 12.4999 2.39999C12.4999 2.02867 12.3524 1.67256 12.0898 1.41C11.8273 1.14743 11.4712 0.999924 11.0998 0.999924C10.7285 0.999924 10.3724 1.14743 10.1098 1.41L4.49984 6.99999V8.99999H6.49984L12.0898 3.38999Z"
            stroke="#EB2701"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className={styles["content"]}>
        {post?.data?.mediaSources && post?.data?.mediaSources?.length > 0 && (
          <img src={post?.data.mediaSources[0]} />
        )}
        <TextElement>{post?.data?.text}</TextElement>
      </div>
    </div>
  );
};

export default PostCard;
