import React, { useEffect } from "react";

import styles from "./ShowCampaignAnalysisData.module.scss";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DisclosureComponent from "../Disclosure/Disclosure";
import TextElement from "../TextElement/TextElement";
import CompetitorStrategies from "../CompetitorStrategies/CompetitorStrategies";
import classNames from "classnames";
import TargetAudienceAndEstimatedNumbersCard from "../TargetAudienceAndEstimatedNumbersCard/TargetAudienceAndEstimatedNumbersCard";
import Recommended5GOfferingsCard from "../RecommendedProductOfferingsCard/RecommendedProductOfferingsCard";
import RecommendedMarketingChannels from "../RecommendedMarketingChannels/RecommendedMarketingChannels";
import BudgetAllocationAndBreakdown from "../BudgetAllocationAndBreakdown/BudgetAllocationAndBreakdown";
import ProjectedROICard from "../ProjectedROICard/ProjectedROICard";
import useCampaignAnalysisData from "./useCampaignAnalysisData";
import CompetitorAnalysisModal from "../CompetitorAnalysisModal/CompetitorAnalysisModal";
import ShowPastInsights from "./componenets/ShowPastInsights";
import ShowAudienceSegments from "./componenets/ShowAudienceSegments";
import MarketSentimentCard from "../MarketSentimentCard/MarketSentimentCard";
import {
  fetchCompetitors,
  fetchMarketSentiments,
  fetchSwotAnalysis,
} from "../../redux/campaignPlanAnalysisQueryChat";
import SwotAnalysisStage from "../SwotAnalysisStage/SwotAnalysisStage";

interface Props {
  className?: string;
  onClick?: () => void;
}

const ShowCampaignAnalysisData: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();
  const {
    hasAudienceSegmentation,
    hasCompetitorAnalysis,
    hasPastInsights,
    hasCampaignRecommendations,
    hasMarketSentiments,
  } = useCampaignAnalysisData();

  const [competitorAnalysisModal, setCompetitorAnalysisModal] =
    React.useState(false);

  const { marketSentiments, swotAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  useEffect(() => {
    if (selectedCampaign && hasMarketSentiments) {
      dispatch(
        fetchMarketSentiments({
          campaignPlanId: selectedCampaign?.id,
          industry: "Salesforce",
        })
      ).catch(() => {
        console.log("Error fetching market sentiments");
      });
    }

    if (selectedCampaign && hasCompetitorAnalysis) {
      dispatch(
        fetchCompetitors({
          campaignPlanId: selectedCampaign?.id,
          industry: "Salesforce",
          companyName: "Infotech",
        })
      ).catch(() => {
        console.log("Error fetching competitors");
      });
      dispatch(
        fetchSwotAnalysis({
          campaignPlanId: selectedCampaign?.id,
          industry: "Salesforce",
          companyName: "Infotech",
        })
      ).catch(() => {
        console.log("Error fetching competitors");
      });
    }
  }, [selectedCampaign, hasMarketSentiments]);

  return (
    <div className={classNames(styles["content"], className)} onClick={onClick}>
      <CompetitorAnalysisModal
        isOpen={competitorAnalysisModal}
        closeModal={() => setCompetitorAnalysisModal(false)}
      />

      {hasPastInsights && (
        <DisclosureComponent
          header={<TextElement>Insights from past campaigns</TextElement>}
          content={<ShowPastInsights />}
        />
      )}

      {hasAudienceSegmentation && (
        <DisclosureComponent
          header={<TextElement>Audience Segmentation</TextElement>}
          content={<ShowAudienceSegments />}
        />
      )}

      {hasMarketSentiments && (
        <DisclosureComponent
          header={<TextElement>Market Sentiments</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {marketSentiments
                ?.filter((sentiment) =>
                  selectedCampaign?.includedData?.marketSentimentIds.includes(
                    sentiment?.id
                  )
                )
                .map((sentiment, index) => (
                  <MarketSentimentCard
                    cardType="ellipses"
                    key={index}
                    marketSentiment={sentiment}
                  />
                ))}
            </div>
          }
        />
      )}

      {hasCompetitorAnalysis && (
        <DisclosureComponent
          header={<TextElement>Competitor Analysis</TextElement>}
          content={
            <>
              <div className={styles["competitor-analysis"]}>
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.competitorStrategies && (
                    <CompetitorStrategies cardType="ellipses" />
                  )}
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.swotAnalysis?.strengths && (
                    <SwotAnalysisStage
                      analysisHeading="Strengths"
                      analysis={swotAnalysis?.strengths}
                      cardType="ellipses"
                    />
                  )}
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.swotAnalysis?.weaknesses && (
                    <SwotAnalysisStage
                      analysisHeading="Weaknesses"
                      analysis={swotAnalysis?.weaknesses}
                      cardType="ellipses"
                    />
                  )}
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.swotAnalysis?.opportunities && (
                    <SwotAnalysisStage
                      analysisHeading="Opportunities"
                      analysis={swotAnalysis?.opportunities}
                      cardType="ellipses"
                    />
                  )}
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.swotAnalysis?.threats && (
                    <SwotAnalysisStage
                      analysisHeading="Threats"
                      analysis={swotAnalysis?.threats}
                      cardType="ellipses"
                    />
                  )}
              </div>
              {selectedCampaign?.includedData &&
                selectedCampaign.includedData.competitorStrategies && (
                  <footer className={styles["disclosure-footer"]}>
                    <a
                      className={styles["underline-text"]}
                      onClick={() => setCompetitorAnalysisModal(true)}
                    >
                      See comparison details
                    </a>
                  </footer>
                )}
            </>
          }
        />
      )}

      {hasCampaignRecommendations && (
        <DisclosureComponent
          header={<TextElement>Campaign Recommendations</TextElement>}
          content={
            <div
              className={classNames(
                styles["campaign-data"],
                styles["recommendation-data"]
              )}
            >
              {selectedCampaign?.includedData?.recommendations
                ?.targetAudienceAndEstimatedNumbers && (
                <TargetAudienceAndEstimatedNumbersCard cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedOfferings && (
                <Recommended5GOfferingsCard cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedChannels && (
                <RecommendedMarketingChannels cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedBudget && (
                <BudgetAllocationAndBreakdown cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.projectedRoi && <ProjectedROICard cardType="ellipses" />}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ShowCampaignAnalysisData;
