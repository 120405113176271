import React, { useEffect } from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";

import styles from "./PostSchedulesCalendarListViewModal.module.scss";
import PostSchedulesCalendarListView from "../PostSchedulesCalendarListView/PostSchedulesCalendarListView";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  onOk: (text: string) => void;
}

const PostSchedulesCalendarListViewModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen, onOk } = props;

  return (
    <Modal  
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      <PostSchedulesCalendarListView />
    </Modal>
  );
};

export default PostSchedulesCalendarListViewModal;
