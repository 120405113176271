import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Login/Login";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import CampaignBuilderLayout from "../componenets/CampaignBuilderLayout/CampaignBuilderLayout";
import MyTeamMember from "./MyTeamMember/MyTeamMember";
import CMOHome from "./CMOHome/CMOHome";
import AddTeamMember from "./AddTeamMember/AddTeamMember";
import MarketerCampaignsList from "./MarketerCampaignsList/MarketerCampaignsList";
import MyBranding from "./MyBranding/MyBranding";
import Notifications from "./Notifications/Notifications";
import MarketerCampaignData from "./CampaignData/MarketerCampaignData";
import ApproverCampaignsList from "./ApproverCampaignsList/ApproverCampaignsList";
import ApproverCampaignData from "./CampaignData/ApproverCampaignData";
import { UserRoleType } from "../redux/authSlice";
import IdentifyValueStep from "./MarketValueSteps/IdentifyValueStep";
import CreateValueStep from "./MarketValueSteps/CreateValueStep";
import RealizeValueStep from "./MarketValueSteps/RealizeValueStep";
import InterestStageLayout from "../componenets/InterestStageLayout/InterestStageLayout";
import ShortlistStageLayout from "../componenets/ShortlistStageLayout/ShortlistStageLayout";
import MarketingPipelineConfiguration from "./MarketingPipelineConfiguration/MarketingPipelineConfiguration";
import MyAccount from "./MyAccount/MyAccount";
import LandingPage from "./LandingPage/LandingPage";
import MarketingStageInside from "./MarketingStageInside/MarketingStageInside";
import SalesStageInside from "./SalesStageInside/SalesStageInside";
import CustomerStageInside from "./CustomerStageInside/CustomerStageInside";
import CampaignPlanList from "./CampaignPlanList/CampaignPlanList";
import ConnectingMarketingChannels from "./ConnectingMarketingChannels/ConnectingMarketingChannels";
import DevelopStrategyStageLayout from "../componenets/DevelopStrategyStageLayout/DevelopStrategyStageLayout";
import NegotiateLayout from "../componenets/NegotiateLayout/NegotiateLayout";
import CustomerRiskProfile from "./CustomerRiskProfile/CustomerRiskProfile";
import CustomerSuccessContentLayout from "../componenets/CustomerSuccessContentLayout/CustomerSuccessContentLayout";

export const ROUTE_DEFINITIONS = {
  LOGIN: {
    path: "/",
    element: <Login />,
  },

  FORGOT_PASSWORD: {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },

  RESET_PASSWORD: {
    path: "/reset-password",
    element: <ResetPassword />,
  },

  HOME: {
    path: "/home",
    element: <LandingPage />,
  },

  MARKETER_IDENTIFY_VALUE: {
    path: "/identify-value",
    element: <IdentifyValueStep />,
  },

  MARKETER_CREATE_VALUE: {
    path: "/create-value",
    element: <CreateValueStep />,
  },

  MARKETER_REALIZE: {
    path: "/realize-value",
    element: <RealizeValueStep />,
  },

  MARKETING_CAMPAGIN_BUILDER: {
    path: "/marketing-campaign-builder",
    element: <CampaignBuilderLayout operation="analysis" />,
  },

  MARKETING_CAMPAGIN_CONTENT_BUILDER: {
    path: "/marketing-campaign-content-builder",
    element: <CampaignBuilderLayout operation="content" />,
  },

  MARKETING_CONNECTING_DATA_SOURCES: {
    path: "/marketing-connecting-data-sources",
    element: <CampaignBuilderLayout operation="connecting-data-sources" />,
  },

  MARKETING_CAMPAGINS_LIST: {
    path: "/marketing-campaigns-list",
    element: <CampaignPlanList userRole={UserRoleType.MARKETING} />,
  },

  MARKETING_CAMPAGIN_DATA: {
    path: "/marketing-campaigns-data",
    element: <MarketerCampaignData />,
  },

  NOTIFICATIONS: {
    path: "/notifications",
    element: <Notifications userRole={UserRoleType.MARKETING} />,
  },

  MY_BRANDING: {
    path: "/my-branding",
    element: <MyBranding roleFor={UserRoleType.MARKETING} />,
  },

  MARKETING_INTEREST_STAGE: {
    path: "/marketing-interest-stage",
    element: <InterestStageLayout operation="report" />,
  },

  MARKETING_NURTURE_STRATEGY_BUILDER: {
    path: "/marketing-nurture-strategy-builder",
    element: <InterestStageLayout operation="strategy" />,
  },

  MARKETING_SHORTLIST_STAGE: {
    path: "/marketing-shortlist-stage",
    element: <ShortlistStageLayout operation="report" />,
  },
  
  TAILORED_STRATEGY_FOR_BANT_QUALIFICATION: {
    path: "/tailored-strategy-for-bant-qualification",
    element: <ShortlistStageLayout operation="content" />,
  },

  MARKETING_CHANNEL_CONNECTIONS: {
    path: "/marketing-channel-connections",
    element: <ConnectingMarketingChannels />,
  },

  APPROVER_CAMPAGINS_LIST: {
    path: "/approver-campaigns-list",
    element: <CampaignPlanList userRole={UserRoleType.PRIMARY_APPROVER} />,
  },

  APPROVER_CAMPAGIN_DATA: {
    path: "/approver-campaigns-data",
    element: <ApproverCampaignData />,
  },

  APPROVER_BRANDING: {
    path: "/approver-branding",
    element: <MyBranding roleFor={UserRoleType.PRIMARY_APPROVER} />,
  },

  APPROVER_NOTIFICATION: {
    path: "/approver-notification",
    element: <Notifications userRole={UserRoleType.PRIMARY_APPROVER} />,
  },

  CMO_HOME: {
    path: "/cmo-home",
    element: <CMOHome />,
  },

  MY_TEAM_MEMBER: {
    path: "/team",
    element: <MyTeamMember />,
  },

  ADD_TEAM_MEMBER: {
    path: "/team/add",
    element: <AddTeamMember />,
  },

  CMO_NOTIFICATION: {
    path: "/cmo-notification",
    element: <Notifications userRole={UserRoleType.CMO} />,
  },

  CMO_BRANDING: {
    path: "/cmo-branding",
    element: <MyBranding roleFor={UserRoleType.CMO} />,
  },

  MARKETING_PIPELINE_CONFIGURATION: {
    path: "/marketing-pipeline-configuration",
    element: <MarketingPipelineConfiguration />,
  },

  CMO_ACCOUNT: {
    path: "/cmo",
    element: <MyAccount role={UserRoleType.CMO} />,
  },

  MARKETER_ACCOUNT: {
    path: "/marketer",
    element: <MyAccount role={UserRoleType.MARKETING} />,
  },

  APPROVER_ACCOUNT: {
    path: "/approver",
    element: <MyAccount role={UserRoleType.PRIMARY_APPROVER} />,
  },

  MARKETING_STAGE_INSIDE: {
    path: "/marketing-stage-inside",
    element: <MarketingStageInside />,
  },

  SALES_STAGE_INSIDE: {
    path: "/sales-stage-inside",
    element: <SalesStageInside />,
  },

  CUSTOMER_STAGE_INSIDE: {
    path: "/customer-stage-inside",
    element: <CustomerStageInside />,
  },

  MARKETER_CAMPAIGN_PLAN_LIST: {
    path: "/marketing-campaign-plan-list",
    element: <CampaignPlanList userRole={UserRoleType.MARKETING} />,
  },

  APPROVER_CAMPAIGN_PLAN_LIST: {
    path: "/approver-campaign-plan-list",
    element: <CampaignPlanList userRole={UserRoleType.PRIMARY_APPROVER} />,
  },

  SALES_QUALIFIED_LEAD_INSIGHTS: {
    path: "/sales-qualified-lead-insights",
    element: <DevelopStrategyStageLayout operation="report" />,
  },

  SALES_DEVELOP_STRATEGY_BUILDER: {
    path: "/sales-develop-strategy-builder",
    element: <DevelopStrategyStageLayout operation="strategy" />,
  },

  SALES_QUALIFIED_OPPORTUNITIES: {
    path: "/sales-qualified-opportunities",
    element: <NegotiateLayout operation="report" />,  
  },

  NEGOTIATE: {
    path: "/negotiate-strategy",
    element: <NegotiateLayout operation="strategy" />,
  },

  CUSTOMER_RISK_PROFILE: {
    path: "/customer-risk-profile",
    element: <CustomerRiskProfile userRole={UserRoleType.MARKETING} />,
  },

  CUSTOMER_RISK_PROFILE_CONTENT: {
    path: "/customer-risk-profile-content",
    element: <CustomerSuccessContentLayout operation="strategy" />,
  },
};

const AppRoutes: React.FC = () => {
  const routeComponents = Object.values(ROUTE_DEFINITIONS).map(
    ({ path, element, isProtected }: any, index) => {
      return <Route key={index} element={element} path={path} />;
    }
  );

  return (
    <BrowserRouter>
      <Routes>{routeComponents}</Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
