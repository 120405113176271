import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useCampaignQueryChat from "../CampaignQueryChat/useCampaignQueryChat";
import { fetchSegments } from "../../redux/campaignPlanAnalysisQueryChat";

export enum CampaignAnalysisType {
  PAST_INSIGHTS = "past-insights",
  AUDIENCE_SEGMENTATION = "audience-segmentation",
  MARKET_SENTIMENTS = "market-sentiments",
  COMPETITOR_ANALYSIS = "competitor-analysis",
  CAMPAIGN_RECOMMENDATIONS = "campaign-recommendations",
}

const useCampaignFlags = () => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const [hasPastInsights, setHasPastInsights] = useState<boolean | null>(false);
  const [hasAudienceSegmentation, setHasAudienceSegmentation] = useState<
    boolean | null
  >(false);
  const [hasMarketSentiments, setHasMarketSentiments] = useState<
    boolean | null
  >(false);
  const [hasCompetitorAnalysis, setHasCompetitorAnalysis] = useState<
    boolean | null
  >(false);
  const [hasCampaignRecommendations, setHasCampaignRecommendations] = useState<
    boolean | null
  >(false);

  useEffect(() => {
    if (!selectedCampaign?.includedData) return;
    setHasPastInsights(
      selectedCampaign &&
        selectedCampaign?.includedData?.pastInsights?.length > 0
    );

    setHasAudienceSegmentation(
      selectedCampaign &&
        selectedCampaign?.includedData?.audienceSegmentation?.length > 0
    );

    setHasMarketSentiments(
      selectedCampaign &&
        selectedCampaign?.includedData?.marketSentimentIds?.length > 0
    );

    setHasCompetitorAnalysis(
      (selectedCampaign &&
        (selectedCampaign.includedData.competitorIds?.length > 0 ||
          selectedCampaign?.includedData?.competitorStrategies)) ||
        selectedCampaign?.includedData?.swotAnalysis?.opportunities ||
        selectedCampaign?.includedData?.swotAnalysis?.strengths ||
        selectedCampaign?.includedData?.swotAnalysis?.weaknesses ||
        selectedCampaign?.includedData?.swotAnalysis?.threats
    );

    setHasCampaignRecommendations(
      selectedCampaign &&
        (selectedCampaign.includedData.recommendations?.projectedRoi ||
          selectedCampaign.includedData.recommendations?.recommendedBudget ||
          selectedCampaign.includedData.recommendations?.recommendedChannels ||
          selectedCampaign.includedData.recommendations?.recommendedOfferings ||
          selectedCampaign.includedData.recommendations
            ?.targetAudienceAndEstimatedNumbers)
    );
  }, [selectedCampaign]);

  return {
    hasPastInsights,
    hasAudienceSegmentation,
    hasMarketSentiments,
    hasCompetitorAnalysis,
    hasCampaignRecommendations,
  };
};

export default useCampaignFlags;
