import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";
import { PostSchedule } from "../componenets/PostSchedulesCalendarListView/usePostSchedulesCalendarListView";

type Message = ["Client" | "Assistant", string];

interface ContentGenerationState {
  posts: PostSchedule[];
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
}

const initialState: ContentGenerationState = {
  posts: [],
  loading: false,
  isLoaded: false,
  error: null,
};

const httpService = new HttpService(process.env.REACT_APP_CONTENT_API_URL);

interface FetchPostsInput {
  campaignPlanId: string;
}

export const fetchPosts = createAsyncThunk<PostSchedule[], FetchPostsInput>(
  "contentGeneration/fetchPosts",
  async (input) => {
    const { campaignPlanId } = input;
    try {
      const response = await httpService.post<PostSchedule[], FetchPostsInput>(
        "generate_content",
        {
          campaignPlanId,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch posts", error);
      throw new Error("Failed to fetch posts");
    }
  }
);

const contentGenerationSlice = createSlice({
  name: "contentGeneration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isLoaded = false;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.posts = action.payload;
        state.loading = false;
        state.isLoaded = true;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.isLoaded = false;
        state.error = "There is no generated content for this campaign plan";
      });
  },
});

export const {} = contentGenerationSlice.actions;
export default contentGenerationSlice.reducer;
