import { configureStore } from "@reduxjs/toolkit";
import marketingBriefSliceReducer from "./marketingBriefSlice";
import campaignPinningSlice from "./campaignPinningSlice";
import authSlice from "./authSlice";
import campaignPlanAnalysisQueryChat from "./campaignPlanAnalysisQueryChat";
import teamMemberSlice from "./teamMemberSlice";
import campaignCommentsSlice from "./campaignCommentsSlice";
import interestStageSlice from "./interestStageSlice";
import notificationSlice from "./notificationsSlice";
import campaignQueryChatScrollControllerSlice from "./CampaignQueryChatScrollControllerSlice";
import erikoAiChatAsistantSlice from "./erikoAiChatAsistantSlice";
import contentGenerationSlice from "./contentGenerationSlice";
import tailoredStrategyforBANTQualificationSlice from "./tailoredStrategyforBANTQualificationSlice";
import salesQualifiedStrategySlice from "./salesQualifiedStrategySlice";
import negotiateStrategySlice from "./negotiateStrategySlice";

export const store = configureStore({
  reducer: {
    marketingBrief: marketingBriefSliceReducer,
    campaignData: campaignPinningSlice,
    auth: authSlice,
    campaignPlanAnalysisQueryChat,
    teamMember: teamMemberSlice,
    campaignComments: campaignCommentsSlice,
    interestStage: interestStageSlice,
    notifications: notificationSlice,
    campaignQueryChatScrollController: campaignQueryChatScrollControllerSlice,
    erikoAiChatAsistant: erikoAiChatAsistantSlice,
    contentGeneration: contentGenerationSlice,
    tailoredStrategyforBANTQualification:
      tailoredStrategyforBANTQualificationSlice,
    salesQualifiedStrategy: salesQualifiedStrategySlice,
    negotiateStrategy: negotiateStrategySlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
