import React, { useEffect } from "react";
import styles from "../ShowCampaignAnalysisData.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchPastCampaignAnalysis } from "../../../redux/campaignPlanAnalysisQueryChat";
import PerformanceMetricsCard from "../../PerformanceMetricsCard/PerformanceMetricsCard";
import { toast } from "react-toastify";

interface ShowPastInsightsProps {}

const ShowPastInsights: React.FC<ShowPastInsightsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { pastCampaignAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchPastCampaignAnalysis({
          campaignPlanId: selectedCampaign?.id,
          industry: "salesforce",
        })
      ).catch(() => {
        console.log("Error fetching past campaign analysis");
        toast.error("Error fetching past campaign analysis");
      });
  }, []);

  return (
    <div className={styles["campaign-data"]}>
      {selectedCampaign &&
        typeof pastCampaignAnalysis !== "string" &&
        pastCampaignAnalysis
          .filter((_, index) =>
            selectedCampaign?.includedData?.pastInsights?.includes(index)
          )
          .map((analysis, index) => (
            <PerformanceMetricsCard
              title={analysis.metric}
              rate={analysis.benchmark}
              description={analysis.explanation}
              id={index}
              key={index}
              cardType="ellipses"
            />
          ))}
    </div>
  );
};

export default ShowPastInsights;
