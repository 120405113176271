import React, { useEffect } from "react";

import styles from "./GeneratedActiveOffers.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";

interface Props {
  className?: string;
  titleContent?: React.ReactNode;
}

const GeneratedActiveOffers: React.FC<Props> = (props) => {
  const { className } = props;
  const [loading, setLoading] = React.useState(true);

  const loadingRef = React.useRef<HTMLImageElement>(null);

  const navigate = useNavigate();

  const handleOfferContent = () => {
    navigate(ROUTE_DEFINITIONS.CUSTOMER_RISK_PROFILE_CONTENT.path);
  };

  useEffect(() => {
    if (loadingRef?.current)
      loadingRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["header"]}>
        <svg
          width="28"
          height="25"
          viewBox="0 0 28 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2 22.2222L7.7 14.5833L0 11.1111L7.7 7.63889L11.2 0L14.7 7.63889L22.4 11.1111L14.7 14.5833L11.2 22.2222ZM22.4 25L20.65 21.1806L16.8 19.4444L20.65 17.7083L22.4 13.8889L24.15 17.7083L28 19.4444L24.15 21.1806L22.4 25Z"
            fill="#EB2701"
          />
        </svg>
        <TextElement>Generated Offers</TextElement>
      </div>
      {loading ? (
        <img
          ref={loadingRef}
          className={styles["loading"]}
          src="gifs/generating-offers 1.gif"
        />
      ) : (
        <div className={styles["offers"]}>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Data Boost</span>
              <br />
              Get an extra 10GB of data free for the next month!
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Discounted Upgrade</span>
              <br />
              Upgrade to our premium 5G plan with a 25% discount for the first
              three months.
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Customer Support Priority</span>
              <br />
              Receive priority customer support to address your concerns
              promptly
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Service Review Call</span>
              <br />
              Schedule a free service review call with a dedicated support
              specialist.
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Bill Payment Extension</span>
              <br />
              Need more time to pay your bill? Request a payment extension.
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Exclusive Device Discount</span>
              <br />
              Upgrade your device with a 30% discount on the latest 5G
              smartphones.
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Free Streaming Service</span>
              <br />
              Enjoy a free subscription to a popular streaming service for three
              months
            </TextElement>
          </div>
          <div className={styles["offer"]} onClick={handleOfferContent}>
            <div className={styles["icon-container"]}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["text"]}>
              <span>Personalized Data Plan</span>
              <br />
              Receive a personalized data plan tailored to your usage habits.
            </TextElement>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneratedActiveOffers;
