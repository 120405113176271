import React, { forwardRef, useEffect } from "react";

import styles from "./MarketingBriefFormCard.module.scss";

import classNames from "classnames";
import Button from "../Button/Button";
import Input from "./components/Input/Input";
import TextArea from "./components/TextArea/TextArea";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createCampaign,
  CreateCampaignResponse,
} from "../../redux/campaignPinningSlice";

import Select from "../Select/Select";
import TextElement from "../TextElement/TextElement";
import { toast } from "react-toastify";

interface MarketingBriefFormCardProps {
  className?: string;
  onSubmit?: () => void;
  onErrorMessage: (message: string) => void;
}

const MarketingBriefFormCard = forwardRef<
  HTMLDivElement,
  MarketingBriefFormCardProps
>((props, ref) => {
  const { className, onSubmit } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const [formData, setFormData] = React.useState({
    productName: "Salesforce Managed Services",
    campaignName: "",
    campaignOverview: "",
    campaignObjective: "",
    campaignStartDate: new Date().getTime(),
    campaignBudget: "",
    isOrganic: false,
  });

  useEffect(() => {
    if (selectedCampaign) {
      setFormData({
        productName: selectedCampaign.productName as string,
        campaignName: selectedCampaign.title as string,
        campaignOverview: selectedCampaign.campaignOverview as string,
        campaignObjective: selectedCampaign.campaignObjective as string,
        campaignStartDate: selectedCampaign.campaignStartDate as number,
        campaignBudget: selectedCampaign.campaignBudget as string,
        isOrganic: selectedCampaign.isOrganic as boolean,
      });
    }
  }, [selectedCampaign]);

  return (
    <div className={classNames(styles["container"], className)} ref={ref}>
      <form
        className={styles["form"]}
        onSubmit={async (e) => {
          e.preventDefault();
          if (!formData?.productName) {
            return toast.error("Please select a product");
          }
          const campaign = {
            title: formData.campaignName,
            campaignOverview: formData.campaignOverview,
            campaignObjective: formData.campaignObjective,
            campaignStartDate: new Date(formData.campaignStartDate).getTime(),
            campaignBudget: formData.campaignBudget,
            productName: formData.productName,
            includedData: {
              pastInsights: [],
              audienceSegmentation: [],
              marketSentimentIds: [],
              competitorIds: [],
              competitorStrategies: false,
              swotAnalysis: {
                strengths: false,
                weaknesses: false,
                opportunities: false,
                threats: false,
              },
              recommendations: {
                targetAudienceAndEstimatedNumbers: false,
                recommendedOfferings: false,
                recommendedChannels: false,
                recommendedBudget: false,
                projectedRoi: false,
              },
              content: {
                googleAds: false,
                contentMarketing: false,
                emailMarketing: false,
                socialMediaAdvertising: false,
              },
            },
            activeAnalysisStep: true,
            isOrganic: false,
          };

          try {
            dispatch(createCampaign(campaign)).then((response) => {
              if (
                response.payload &&
                (response.payload as CreateCampaignResponse).success
              ) {
                onSubmit && onSubmit();
              } else {
                toast.error("Error occurred during creating campaign");
              }
            });
          } catch (error) {
            console.error("Error occurred during dispatch:", error);
            toast.error("Error occurred during dispatch");
          }
        }}
      >
        <div className={styles["select-container"]}>
          <TextElement className={styles["select-label"]}>Product</TextElement>
          <Select
            options={[
              {
                label: "Salesforce Managed Services",
                value: "Salesforce Managed Services",
              },
            ]}
            value={formData.productName}
            onChange={(value) =>
              setFormData({ ...formData, productName: value as string })
            }
            className={styles["select"]}
            optionClassName={styles["option"]}
          />
        </div>
        <Input
          label={"Campaign Name*"}
          value={formData.campaignName}
          required
          placeholder="Campaign Name"
          onChange={(e) =>
            setFormData({ ...formData, campaignName: e.target.value })
          }
        />
        <TextArea
          label={"Campaign Overview*"}
          value={formData.campaignOverview}
          onChange={(e) =>
            setFormData({ ...formData, campaignOverview: e.target.value })
          }
          rows={8}
          placeholder="Share a brief summary of your campaign: its goals, target audience, and key messages. Also, list the marketing channels where you'll be promoting the campaign."
        />
        <TextArea
          label={"Campaign Objective*"}
          value={formData.campaignObjective}
          onChange={(e) =>
            setFormData({ ...formData, campaignObjective: e.target.value })
          }
          rows={8}
          placeholder=""
        />
        <div className={classNames(styles["campaign-budget-container"])}>
          <div className={classNames(styles["checkbox-container"])}>
            <input
              type="checkbox"
              checked={formData?.isOrganic}
              onChange={(e) => {
                // setIsBudgetPaidActive(e.target.checked);
                setFormData({ ...formData, isOrganic: e.target.checked });
              }}
            />
            <label>Paid Campaign</label>
          </div>
          <Input
            label={"Campaign Budget*"}
            value={formData.campaignBudget}
            onChange={(e) =>
              setFormData({ ...formData, campaignBudget: e.target.value })
            }
            disabled={!formData?.isOrganic}
            inputClassName={classNames(
              styles["date-input"],
              !formData?.isOrganic && styles["disabled-input"]
            )}
            placeholder="Exp: $1000, $500M, $1B, etc."
            min={160}
          />
        </div>
        <Input
          label={"Campaign Start Date*"}
          value={new Date().toISOString().substring(0, 10)}
          type="date"
          onChange={(e) => {
            setFormData({
              ...formData,
              campaignStartDate: new Date(e.target.value).getTime(),
            });
          }}
          inputClassName={styles["date-input"]}
          placeholder="Campaign Start Date"
        />

        <div className={styles["footer"]}>
          <Button
            className={styles["submit"]}
            disabled={selectedCampaign ? true : false}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
});

export default MarketingBriefFormCard;
