import React, { useEffect } from "react";

import styles from "./CampaignHeader.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ShareCampaignModal from "../ShareCampaignModal/ShareCampaignModal";
import { LuHome } from "react-icons/lu";
import { IoMdShare } from "react-icons/io";
import { updateCampaign } from "../../redux/campaignPinningSlice";

type Props = {
  className?: string;
};

const CampaignHeader: React.FC<Props> = (props) => {
  const { className } = props;
  const [campaignName, setCampaignName] =
    React.useState<string>("Untitled Campaign");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedCampaign, loading } = useAppSelector(
    (state) => state.campaignData
  );
  const [isShareModalOpen, setIsShareModalOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.title as string);
    } else {
      setCampaignName("Untitled Campaign");
    }
  }, [selectedCampaign]);

  return (
    <div className={classNames(styles["container"], className)}>
      <ShareCampaignModal
        isOpen={isShareModalOpen}
        closeModal={() => {
          setIsShareModalOpen(false);
        }}
      />
      <button
        className={styles["logo-container"]}
        onClick={() => navigate(ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path)}
      >
        <LuHome />
        <TextElement>Back</TextElement>
      </button>
      <input
        value={campaignName}
        className={styles["campaign-name-input"]}
        onChange={(e) => {
          selectedCampaign &&
            dispatch(
              updateCampaign({
                ...selectedCampaign,
                title: e.target.value,
              })
            );
          setCampaignName(e.target.value);
        }}
        disabled={!selectedCampaign}
      />
      <div className={styles["save-share-container"]}>
        {location.pathname ===
          ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path ||
        location.pathname === ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path ||
        location.pathname ===
          ROUTE_DEFINITIONS.MARKETING_SHORTLIST_STAGE.path ? (
          <TextElement className={styles["update-status-text"]}>
            {loading ? "Saving..." : "All changes saved"}
          </TextElement>
        ) : (
          <IoMdShare className={styles["share-icon"]} />
        )}
        {location.pathname ===
          ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path ||
        location.pathname === ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path ||
        location.pathname ===
          ROUTE_DEFINITIONS.MARKETING_SHORTLIST_STAGE.path ? (
          <Button
            className={styles["share-button"]}
            onClick={() => setIsShareModalOpen(true)}
            variant="outline"
          >
            Share
          </Button>
        ) : (
          <Button className={classNames(styles["share-button"])}>Save</Button>
        )}
      </div>
    </div>
  );
};

export default CampaignHeader;
