import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchPosts } from "../../redux/contentGenerationSlice";
import { toast } from "react-toastify";

export type PostSchedule = {
  post_schedule_id: string;
  recommendation_plan_id: string;
  theme: string;
  published_time: string;
  post_number: number;
  content_type_id: string;
  channel_id: string;
  post_id: string;
  post_date: string;
  data: {
    text?: string;
    mediaSources?: string[];
  };
  status: string;
};

const usePostSchedulesCalendarListView = () => {
  const [postSchedulesWithData, setPostSchedulesWithData] = useState<
    PostSchedule[]
  >([]);

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const dispatch = useAppDispatch();

  const { posts, loading, error , isLoaded} = useAppSelector(
    (state) => state.contentGeneration
  );

  useEffect(() => {
    if (selectedCampaign) {
      dispatch(
        fetchPosts({
          campaignPlanId: selectedCampaign.id,
        })
      ).catch(() => {
        toast.error("Failed to fetch posts");
      });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (posts.length > 0) {
      setPostSchedulesWithData(posts);
    }
  }, [posts]);

  const [dateRange, setDateRange] = useState<{
    startDateRange: Date | null;
    endDateRange: Date | null;
  }>({
    startDateRange: null,
    endDateRange: null,
  });

  useEffect(() => {
    setDateRange(getDateRange(postSchedulesWithData));
  }, [postSchedulesWithData]);

  const getDateRange = (
    postSchedules: PostSchedule[]
  ): { startDateRange: Date | null; endDateRange: Date | null } => {
    if (postSchedules.length === 0) {
      return { startDateRange: null, endDateRange: null };
    }

    let startDateRange: Date = new Date(postSchedules[0].published_time);
    let endDateRange: Date = new Date(postSchedules[0].published_time);

    postSchedules.forEach((post) => {
      const postDate = new Date(post.published_time);
      if (postDate < startDateRange) {
        startDateRange = postDate;
      }
      if (postDate > endDateRange) {
        endDateRange = postDate;
      }
    });

    return {
      startDateRange,
      endDateRange,
    };
  };

  const filterPostsByDate = (
    posts: PostSchedule[],
    targetDate: Date
  ): PostSchedule[] => {
    return posts.filter((post) => {
      const postDate = new Date(post.published_time);

      const postLocalDate = new Date(
        postDate.getUTCFullYear(),
        postDate.getUTCMonth(),
        postDate.getUTCDate(),
        postDate.getUTCHours(),
        postDate.getUTCMinutes(),
        postDate.getUTCSeconds()
      );

      // Get local date and month
      const localDay = postLocalDate.getDate();
      const localMonth = postLocalDate.getMonth();

      // Get target date and month
      const targetDay = targetDate.getDate();
      const targetMonth = targetDate.getMonth();

      return localMonth === targetMonth && localDay === targetDay;
    });
  };

  const groupPostsByDayInMonth = (
    posts: PostSchedule[],
    targetMonth: number,
    targetYear: number
  ) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Filter posts for the target month and year
    const filteredPosts = posts.filter((post) => {
      const postDate = new Date(post.published_time);
      return (
        postDate.getMonth() === targetMonth &&
        postDate.getFullYear() === targetYear
      );
    });

    // Group posts by day with string keys
    const groupedPosts = filteredPosts.reduce((acc, post) => {
      const postDate = new Date(post.published_time);

      const postLocalDate = new Date(
        postDate.getUTCFullYear(),
        postDate.getUTCMonth(),
        postDate.getUTCDate(),
        postDate.getUTCHours(),
        postDate.getUTCMinutes(),
        postDate.getUTCSeconds()
      );

      // Get local date and month
      const day = postLocalDate.getDate();
      const targetMonth = postLocalDate.getMonth();

      const monthName = monthNames[targetMonth];

      const key = `${monthName} ${day}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(post);

      return acc;
    }, {} as Record<string, PostSchedule[]>);

    return groupedPosts;
  };

  return {
    postSchedulesWithData,
    dateRange,
    filterPostsByDate,
    groupPostsByDayInMonth,
    loading,
    error,
    isLoaded
  };
};

export default usePostSchedulesCalendarListView;
