import React, { useEffect } from "react";
import styles from "./InterestStageLayoutSideBar.module.scss";
import { FaHome, FaUser, FaCog } from "react-icons/fa";
import classNames from "classnames";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../../../pages/AppRoutes";

interface Props {
  className?: string;
}

enum DevelopStrategySideBarItems {
  KNOWN_CONTACTS = "KNOWN_CONTACTS",
  CONTENT = "CONTENT",
}

const InterestStageLayoutSideBar: React.FC<Props> = (props) => {
  const { className } = props;
  const [activeItem, setActiveItem] =
    React.useState<DevelopStrategySideBarItems>(
      DevelopStrategySideBarItems.KNOWN_CONTACTS
    );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    console.log(window.location.pathname, "window.location.pathname");
    if (
      window.location.pathname ===
      ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path
    ) {
      setActiveItem(DevelopStrategySideBarItems.KNOWN_CONTACTS);
    } else if (
      window.location.pathname ===
      ROUTE_DEFINITIONS.MARKETING_NURTURE_STRATEGY_BUILDER.path
    ) {
      setActiveItem(DevelopStrategySideBarItems.CONTENT);
    }
  }, [window.location.pathname]);

  return (
    <div className={classNames(styles["container"], className)}>
      <div
        className={classNames(
          classNames(styles["icon-container"]),
          activeItem === DevelopStrategySideBarItems.KNOWN_CONTACTS
            ? styles["active"]
            : ""
        )}
        onClick={(e) => {
          console.log(e, "---");
          navigate(ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path);

          e.stopPropagation();
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 9L0 7V3L3.5 1L7 3V7L3.5 9ZM3.5 6.7L5 5.85V4.15L3.5 3.3L2 4.15V5.85L3.5 6.7ZM14.5 9.775V7.45L18 9.5V16.5L12 20L6 16.5V9.5L9.5 7.45V9.775L8 10.65V15.35L12 17.675L16 15.35V10.65L14.5 9.775ZM11 12V0H20L18 3L20 6H13V12H11Z"
            fill="white"
          />
        </svg>
      </div>

      <div
        className={classNames(
          classNames(styles["icon-container"]),
          activeItem === DevelopStrategySideBarItems.CONTENT
            ? styles["active"]
            : ""
        )}
        onClick={(e) => {
          navigate(ROUTE_DEFINITIONS.MARKETING_NURTURE_STRATEGY_BUILDER.path);
          e.stopPropagation();
        }}
      >
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 18L0 6L3 0H17L20 6L10 18ZM7.625 5H12.375L10.875 2H9.125L7.625 5ZM9 13.675V7H3.45L9 13.675ZM11 13.675L16.55 7H11V13.675ZM14.6 5H17.25L15.75 2H13.1L14.6 5ZM2.75 5H5.4L6.9 2H4.25L2.75 5Z"
            fill="#EB2701"
          />
        </svg>
      </div>
    </div>
  );
};

export default InterestStageLayoutSideBar;
