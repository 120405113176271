import { useEffect, useState } from "react";
import HttpService from "../../lib/api";
import { toast } from "react-toastify";

export type Company = {
  id: string;
  name: string;
  industry: string;
  address: string;
  website: string;
  aboutCompany: string;
  mission: string;
  vision: string;
  services: string;
};

export const useMyBranding = () => {
  const [company, setCompany] = useState<Company>({
    id: "",
    name: "",
    industry: "",
    address: "",
    website: "",
    aboutCompany: "",
    mission: "",
    vision: "",
    services: "",
  });

  const httpService = new HttpService();

  useEffect(() => {
    httpService.get<Company[]>("api/company-info/").then((response) => {
      if (response?.data?.length > 0) {
        setCompany(response.data[0]);
      }
    });
  }, []);

  const handleChangeCompanyFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  // Submit function
  const handleSubmitCompanyData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Updated Company Data:", company);

    httpService
      .update<Company, Company>(`api/company-info/${company?.id}/`, company)
      .then(() => {
        toast.success("Company updated Successfully!");
      })
      .catch(() => {
        toast.error("Faild: Update Company Information");
      });
  };

  return {
    company,
    handleChangeCompanyFormData,
    handleSubmitCompanyData,
  };
};
