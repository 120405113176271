import React, { useEffect, useState } from "react";
import styles from "./SwotAnalysisStage.module.scss";
import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  cardType: CardType;
  analysisHeading: "Strengths" | "Weaknesses" | "Opportunities" | "Threats";
  analysis: {
    pointName: string;
    description: string;
  }[];
}

const SwotAnalysisStage: React.FC<Props> = (props) => {
  const { className, cardType, analysisHeading, analysis } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  // State for showing more or less
  const [showMore, setShowMore] = useState(false);
  const maxItemsToShow = 4;

  const handlePin = (isPinned: boolean) => {
    if (selectedCampaign?.includedData) {
      const updatedSwotAnalysis = {
        ...selectedCampaign.includedData?.swotAnalysis,
        [analysisHeading.toLowerCase()]: isPinned,
      };

      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            swotAnalysis: updatedSwotAnalysis,
          },
        })
      );
    }
  };

  const getIcon = () => {
    switch (analysisHeading) {
      case "Opportunities":
        return (
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 18C6.505 18 6.0814 17.8239 5.7292 17.4717C5.3764 17.1189 5.2 16.695 5.2 16.2H8.8C8.8 16.695 8.6239 17.1189 8.2717 17.4717C7.9189 17.8239 7.495 18 7 18ZM4.3 15.3C4.045 15.3 3.8314 15.2136 3.6592 15.0408C3.4864 14.8686 3.4 14.655 3.4 14.4C3.4 14.145 3.4864 13.9314 3.6592 13.7592C3.8314 13.5864 4.045 13.5 4.3 13.5H9.7C9.955 13.5 10.1689 13.5864 10.3417 13.7592C10.5139 13.9314 10.6 14.145 10.6 14.4C10.6 14.655 10.5139 14.8686 10.3417 15.0408C10.1689 15.2136 9.955 15.3 9.7 15.3H4.3ZM3.625 12.6C2.59 11.985 1.7689 11.16 1.1617 10.125C0.5539 9.09 0.25 7.965 0.25 6.75C0.25 4.875 0.9064 3.2814 2.2192 1.9692C3.5314 0.6564 5.125 0 7 0C8.875 0 10.4686 0.6564 11.7808 1.9692C13.0936 3.2814 13.75 4.875 13.75 6.75C13.75 7.965 13.4464 9.09 12.8392 10.125C12.2314 11.16 11.41 11.985 10.375 12.6H3.625Z"
              fill="#EB2701"
            />
          </svg>
        );

      case "Strengths":
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0C4.03106 0 0 4.03106 0 9C0 13.9689 4.03106 18 9 18C13.9689 18 18 13.9689 18 9C18 4.03106 13.9689 0 9 0ZM9 16.1964C5.03206 16.1964 1.80361 12.9679 1.80361 9C1.80361 5.03206 5.03206 1.80361 9 1.80361C12.9679 1.80361 16.1964 5.03206 16.1964 9C16.1964 12.9679 12.9679 16.1964 9 16.1964ZM9.67635 2.68738L5.61824 10.3527H8.4499V15.3126L12.3818 7.64729H9.67635V2.68738Z"
              fill="#EB2701"
            />
          </svg>
        );

      case "Threats":
        return (
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.736843 16H19.2632L10 0L0.736843 16ZM10.8421 13.4737H9.1579V11.7895H10.8421V13.4737ZM10.8421 10.1053H9.1579V6.73684H10.8421V10.1053Z"
              fill="#EB2701"
            />
          </svg>
        );

      case "Weaknesses":
        return (
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0C12.1467 0 11.3111 0.222222 10.5733 0.613333L9 5.33333H11.6667L9 14.2222L9.88889 6.22222H7.22222L8.59111 1.43111C7.64 0.542222 6.34222 0 5 0C2.26222 0 0.111111 2.15111 0.111111 4.88889C0.111111 8.56 3.80889 11.2711 9 16C13.8622 11.6089 17.8889 8.65778 17.8889 4.88889C17.8889 2.15111 15.7378 0 13 0Z"
              fill="#EB2701"
            />
          </svg>
        );
    }
  };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      type={cardType}
    >
      <div className={styles["header"]}>
        <div className={styles["icon-container"]}>{getIcon()}</div>
        <TextElement className={styles["heading"]}>
          {analysisHeading}
        </TextElement>
      </div>
      {analysis
        ?.slice(0, showMore ? analysis?.length : maxItemsToShow)
        .map((analy, index) => (
          <div className={styles["strategy"]} key={index}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7 13.3L13.3 10.7C13.4833 10.5167 13.575 10.2833 13.575 10C13.575 9.71667 13.4833 9.48333 13.3 9.3L10.675 6.675C10.4917 6.49167 10.2627 6.404 9.988 6.412C9.71267 6.42067 9.48333 6.51667 9.3 6.7C9.11667 6.88333 9.025 7.11667 9.025 7.4C9.025 7.68333 9.11667 7.91667 9.3 8.1L10.2 9H6.975C6.69167 9 6.45833 9.09567 6.275 9.287C6.09167 9.479 6 9.71667 6 10C6 10.2833 6.096 10.5207 6.288 10.712C6.47933 10.904 6.71667 11 7 11H10.2L9.275 11.925C9.09167 12.1083 9.004 12.3377 9.012 12.613C9.02067 12.8877 9.11667 13.1167 9.3 13.3C9.48333 13.4833 9.71667 13.575 10 13.575C10.2833 13.575 10.5167 13.4833 10.7 13.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
                fill="#EB2701"
              />
            </svg>
            <div>
              <TextElement className={styles["sub-heading"]}>
                {analy?.pointName}
              </TextElement>
              <TextElement>{analy?.description}</TextElement>
            </div>
          </div>
        ))}
      {analysis?.length > maxItemsToShow && (
        <button className={styles["show-more-btn"]} onClick={toggleShowMore}>
          {showMore ? "See less..." : "See more..."}
        </button>
      )}
    </Card>
  );
};

export default SwotAnalysisStage;
